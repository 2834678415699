import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function HeroSection(props) {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            subtitle="Gliide is an Email Signature Builder for the modern age."
            size={4}
          />
          <SectionHeader
            subtitle="Simply copy + paste any LinkedIn Profile to automatically generate multiple signature templates — and then customize & deploy! We help companies stand out in inboxes by offering consistent, animated email branding for teams & individuals."
            size={4}
          />
          <SectionHeader
            subtitle="With Gliide, you can:"
            size={4}
          />
        </Box>
          <Box width="500px" mx="auto">
            <ol>
              <li>
                <Typography>Increase Email Response Rate.</Typography>
              </li>
              <li>
                <Typography>Increase ‘Click Through’ Activity with Custom Buttons.</Typography>
              </li>
              <li>
                <Typography>Elevate Branding & Email Engagement.</Typography>
              </li>
            </ol>
          </Box>
      </Container>
    </Section>
  );
}

export default HeroSection;
