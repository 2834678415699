import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";

const useStyles = makeStyles((theme) => ({
  // Increase <Container> padding so it's
  // at least half of <Grid> spacing to
  // avoid horizontal scroll on mobile.
  // See https://material-ui.com/components/grid/#negative-margin
  container: {
    padding: `0 ${theme.spacing(3)}px`,
    textAlign: "center",
  },
}));

function CtaSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  console.log(auth);

  let displayCTA = !auth || !auth.user || !auth.user.planIsActive;

  if (displayCTA) {
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container className={classes.container}>
          <Grid
            container={true}
            alignItems="center"
            justifyContent="center"
            spacing={5}
          >
            <Grid item={true} xs={12} md="auto">
              <SectionHeader
                title={props.title}
                subtitle={props.subtitle}
                size={4}
              />
            </Grid>
            <Grid item={true} xs={12} md="auto">
              <Button
                component={Link}
                to={props.buttonPath}
                variant="contained"
                size="large"
                color={props.buttonColor}
              >
                {props.buttonText}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Section>
    );
  } else {
    return null;
  }
}

export default CtaSection;
