import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Slider, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { updateSignature } from "./../../util/db";
import GradientButton from "../GradientButton";

const CTA_STYLES = [
  {
    primaryColor: "#1CA0FC",
    secondaryColor: "#00D5C8",
    textColor: "white",
    border: "none",
  },
  {
    primaryColor: "#FF512F",
    secondaryColor: "#DD2476",
    textColor: "white",
    border: "none",
  },
  {
    primaryColor: "#26D0CE",
    secondaryColor: "#1A2980",
    textColor: "white",
    border: "none",
  },
  {
    primaryColor: "#FFB347",
    secondaryColor: "#FFCC33",
    textColor: "white",
    border: "none",
  },
  {
    primaryColor: "#4776E6",
    secondaryColor: "#8E54E9",
    textColor: "white",
    border: "none",
  },
  {
    primaryColor: "#ffffff",
    secondaryColor: "#ffffff",
    textColor: "black",
    border: "3px solid black",
  },
];

const TabCTA = ({ signature }) => {
  const [cta, setCTA] = useState(signature.cta);

  useEffect(() => {
    setCTA(signature.cta);
    setSize(signature.cta.size || 50);
  }, [signature.cta]);

  const onTextChange = (event) => {
    const newCTA = { ...cta, text: event.target.value };
    setCTA(newCTA);
    updateSignature(signature.id, { cta: newCTA });
  };

  const onUrlChange = (event) => {
    const newCTA = { ...cta, url: event.target.value };
    setCTA(newCTA);
    updateSignature(signature.id, { cta: newCTA });
  };

  const canClearSignature = cta.text || cta.url;
  const onClearSignature = () => {
    const newCTA = { ...cta, text: "", url: "" };
    setCTA(newCTA);
    updateSignature(signature.id, { cta: newCTA });
  };

  const onSelectCTAStyle = (style) => () => {
    const newCTA = { ...cta, ...style, size };
    setCTA(newCTA);
    updateSignature(signature.id, { cta: newCTA });
  };

  // Handle size change
  const [size, setSize] = useState(signature.cta.size || 50);
  const handleSizeChange = (event, newValue) => {
    setSize(newValue);
  };
  const handleSizeChangeCommitted = (event, newValue) => {
    const newCTA = { ...cta, size: newValue };
    setCTA(newCTA);
    updateSignature(signature.id, { cta: newCTA });
  };

  return (
    <>
      <Box m={1} mb={4}>
        <TextField
          label="Call to Action"
          fullWidth
          margin="normal"
          value={cta.text}
          onChange={onTextChange} // This is a simplistic approach; consider using state or a form library for better handling
        />
        <TextField
          label="URL"
          fullWidth
          margin="normal"
          value={cta.url}
          onChange={onUrlChange} // This is a simplistic approach; consider using state or a form library for better handling
        />
      </Box>
      <Grid container spacing={3} m={1}>
        {CTA_STYLES.map((styles, index) => (
          <Grid item xs={4} key={index}>
            <Box display="flex" justifyContent="center">
              <GradientButton
                text="CTA"
                textColor="transparent"
                primaryColor={styles.primaryColor}
                secondaryColor={styles.secondaryColor}
                handleClick={onSelectCTAStyle(styles)}
                border={styles.border === "none" ? "3px solid transparent" : styles.border}
                width="150px"
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box m={1} mt={4}>
        <Typography gutterBottom>Button Size</Typography>
        <Slider
          value={size}
          onChange={handleSizeChange}
          onChangeCommitted={handleSizeChangeCommitted}
          aria-labelledby="continuous-slider"
          valueLabelDisplay="auto"
          min={0}
          max={100}
        />
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={onClearSignature}
          disabled={!canClearSignature}
        >
          Remove
        </Button>
      </Box>
    </>
  );
};

export default TabCTA;
