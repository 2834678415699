import React, { useState } from "react";
import { Grid, Button, Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"; // Add this import

import MuiAlert from "@material-ui/lab/Alert";
import { useRouter } from "./../../util/router";
import { useAuth } from "./../../util/auth";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '12px 24px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TabExport = ({ signature }) => {
  const auth = useAuth();
  const router = useRouter();
  const classes = useStyles();

  const subscribed =
    auth && auth.user && (
      auth.user.stripeSubscriptionStatus === "active" ||
      auth.user.stripeSubscriptionStatus === "trialing" ||
      auth.user.stripeSubscriptionStatus === "trial_will_end"
    );

  // Export Rich Text
  const onExport = async () => {
    if (!auth.user) {
      router.push("/auth/signup?next=/pricing");
      return;
    }

    if (!subscribed) {
      router.push("/pricing");
      return;
    }

    var doc = document,
      text = doc.getElementById("renderTable"),
      range,
      selection;

    if (doc.body.createTextRange) {
      range = doc.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = doc.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    setOpen(true);
  };

  // Export HTML
  const onExportHTML = async () => {
    if (!auth.user) {
      router.push("/auth/signup?next=/pricing");
      return;
    }

    if (!subscribed) {
      router.push("/pricing");
      return;
    }

    var doc = document,
      text = doc.getElementById("renderTable").outerHTML;

    // copy to clipboard
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
    setOpen(true);
  };

  // Snackbar
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Return Component
  return (
    <>
      <Grid container display="flex" justifyContent="center" spacing={4}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onExport} className={classes.button}>
            Copy Signature!
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onExportHTML} className={classes.button}>
            Copy HTML!
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info">
          Exported Signature to Clipboard
        </Alert>
      </Snackbar>
    </>
  );
};

export default TabExport;
