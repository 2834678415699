import React, { useCallback, useEffect, useState } from "react";
import { Box, InputLabel, Button, Typography } from "@material-ui/core";
import { ref, getDownloadURL, uploadBytesResumable } from "@firebase/storage";
import { v4 as uuidv4 } from "uuid";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ImageDropzone from "./ImageDropzone";
import { updateSignature } from "./../../util/db";
import { storage } from "./../../util/firebase";

const TabImages = ({ signature }) => {
  // Update Images References
  const updateImages = useCallback(
    (primary, secondary) => {
      updateSignature(signature.id, {
        images: {
          primary: primary,
          secondary: secondary,
        },
        rerender: true,
      });
    },
    [signature.id]
  );

  // Dropzone Management
  const onDrop = useCallback(
    (file, setNewImage, updateImage) => {
      if (!file) {
        setNewImage(null);
      }
      setNewImage(Object.assign(file, { preview: URL.createObjectURL(file) }));
      console.log("File dropped: ", file);

      const extension = file.name.split(".").pop();
      const file_id = uuidv4();
      const uploadRef = ref(
        storage,
        `sig/${signature.id}/${file_id}.${extension}`
      );
      const uploadTask = uploadBytesResumable(uploadRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log("Upload Failed: ", error);
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            updateImage(downloadURL);
            // TODO: delete old image
          });
        }
      );

      console.log("did it work?");
    },
    [signature.id]
  );

  // Primary Image
  const [newPrimaryImage, setNewPrimaryImage] = useState();
  const primaryImageURL =
    (newPrimaryImage && newPrimaryImage.preview) || signature.images.primary;

  const onDropPrimary = useCallback(
    (file) => {
      onDrop(file, setNewPrimaryImage, (imageUrl) => {
        updateImages(imageUrl, signature.images.secondary);
      });
    },
    [onDrop, setNewPrimaryImage, updateImages, signature.images.secondary]
  );
  useEffect(() => {
    setNewPrimaryImage(undefined);
  }, [signature.images.primary]);

  // Secondary Image
  const [newSecondaryImage, setNewSecondaryImage] = useState();
  const secondaryImageURL =
    (newSecondaryImage && newSecondaryImage.preview) ||
    signature.images.secondary;
  const onDropSecondary = useCallback(
    (file) => {
      onDrop(file, setNewSecondaryImage, (imageUrl) => {
        updateImages(signature.images.primary, imageUrl);
      });
    },
    [onDrop, setNewSecondaryImage, updateImages, signature.images.primary]
  );
  useEffect(() => {
    setNewSecondaryImage(undefined);
  }, [signature.images.secondary]);

  // Swap Images
  const handleSwapImages = () => {
    updateImages(signature.images.secondary, signature.images.primary);
  };

  // Clear newSecondaryImages, newPrimaryImages when signature.images.primary or secondary changes

  // Display
  return (
    <Box display="flex" flexDirection="column">
      <Box
        m={1}
        display="flex"
        alignContent="space-around"
        justifyContent="space-evenly"
      >
        {/* Primary Image */}
        <Box m={3}>
          <InputLabel id="image-primary-label">Primary Image</InputLabel>
          <ImageDropzone image={primaryImageURL} onImageDrop={onDropPrimary} />
        </Box>
        {/* Swamp Images */}
        <Box display="flex" justifyContent="center" flexDirection="column">
          <Box textAlign="center" mt={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSwapImages}
            >
              Swap Images
            </Button>
          </Box>
          <Box textAlign="center" mt={1}>
            <AutorenewIcon onClick={handleSwapImages} />
          </Box>
        </Box>
        {/* Secondary Image */}
        <Box m={3}>
          <InputLabel id="image-secondary-label">Secondary Image</InputLabel>
          <ImageDropzone
            image={secondaryImageURL}
            onImageDrop={onDropSecondary}
          />
        </Box>
      </Box>
      <Box textAlign="center">
        <Typography variant="body2">
          Easily click + upload new images here!
        </Typography>
      </Box>
    </Box>
  );
};

export default TabImages;
