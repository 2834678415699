import React from "react";
import { Button } from "@material-ui/core";

const GradientButton = ({
  text,
  textColor,
  primaryColor,
  secondaryColor,
  handleClick,
  border,
  width,
  height,
  size = 50
}) => {
    // Calculate size-dependent properties
    const fontSize = 14 + (size / 100) * 10; // 14px to 24px
    const paddingHorizontal = 10 + (size / 100) * 10; // 10px to 20px
    const paddingVertical = 5 + (size / 100) * 5; // 5px to 10px
    const borderRadius = 4 + (size / 100) * 4; // 4px to 8px

  const inlineStyles = {
    lineHeight: "1",
    color: textColor || "white",
    display: "inline-block",
    border: border || "none",
    borderRadius: borderRadius,
    cursor: "pointer",
    backgroundImage: `linear-gradient(to right, ${primaryColor} 0%, ${secondaryColor} 51%, ${primaryColor} 100%)`,
    padding: `${paddingVertical}px ${paddingHorizontal}px`,
    textAlign: "center",
    // transition: "0.25s",
    backgroundSize: "200% auto",
    marginLeft: "4px",
    textDecoration: "none",
    fontWeight: "800",
    whiteSpace: "nowrap",
    fontSize: fontSize,
  };
  if (width) {
    inlineStyles["width"] = width;
  }
  if (height) {
    inlineStyles["height"] = width;
  }
  return (
    <Button variant="contained" onClick={handleClick} style={inlineStyles}>
      {text}
    </Button>
  );
};

export default GradientButton;
