import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "What are the benefits?",
      answer: (
        <ol>
          <li>
            <Typography>Increase Email Response Rate.</Typography>
          </li>
          <li>
            <Typography>Increase ‘Click Through’ Activity with Custom Buttons.</Typography>
          </li>
          <li>
            <Typography>Elevate Branding & Email Engagement.</Typography>
          </li>
        </ol>
      )
    },
    {
      question: "Will this effect email deliverability?",
      answer:
        "Our signatures are ”light weight” to ensure deliverability.",
    },
    {
      question: "Can I update or change my signature at any time?",
      answer:
        "Yes, update or change your signature anytime after signing in on app.gliide.ai!",
    },
    {
      question: "What email servers are you compatible with?",
      answer:
        "We support Gmail & Outlook.",
    },
    {
      question: "Can we add multiple users from our team?",
      answer:
        "Yes, individually for now - and eventually we’ll have a ‘Teams’ management feature!",
    },
    {
      question: "Where do I cancel my account?",
      answer:
        "Account --> Settings --> Billing --> Cancel Plan!",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h6">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              {typeof item.answer === 'string' ? (
                <Typography>{item.answer}</Typography>
              ) : (
                item.answer
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
