import React from "react";
import { Box, Typography } from "@material-ui/core";

const TabExport = ({ signature }) => {

  return (
    <>
      <Box mt={3} display="flex" justifyContent="center">
        <video
          width="100%"
          max-width="640"
          controls
          style={{ maxWidth: '640px' }}
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/gliide-ai.appspot.com/o/assets%2Ffinish_video.mp4?alt=media&token=b9206d73-1638-4139-a067-89a631ade72a"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Typography>For Outlook users, we suggest non-animated signatures with minimal buttons.</Typography>
      </Box>
    </>
  );
};

export default TabExport;
