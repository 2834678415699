import React, { useEffect } from "react";
import {
  Grid,
  Container,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import ImageIcon from "@material-ui/icons/Image";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import LinkIcon from "@material-ui/icons/Link";
import PublishIcon from "@material-ui/icons/Publish";
import StarsIcon from "@material-ui/icons/Stars";
// import StorageIcon from "@material-ui/icons/Storage";
import Section from "../Section";
// import GliideSignature from "./display";
import TabAnimation from "./TabAnimation";
import TabImages from "./TabImages";
import TabText from "./TabText";
import TabLinks from "./TabLinks";
import TabCTA from "./TabCTA";
import TabExport from "./TabExport";
import TabExportHelp from "./TabExportHelp";
// import TabDebug from "./TabDebug";
import GliideSignature from "./display";
import GradientButton from "../GradientButton";
import { callTriggerAnimationRender } from "../../util/signature";
import { useRouter } from "../../util/router";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "25px",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
  },
  iconLabel: {
    marginLeft: theme.spacing(1),
  },
  tabLabelIcon: {
    display: "flex",
    flexDirection: "row", // Ensures the icon is to the left and label to the right
    alignItems: "center", // Aligns the icon and label vertically
  },
  tab: {
    midWidth: 35,
  },
}));

const TabIcon = ({ icon: Icon, activeTab, label, showLabel, index }) => {
  const classes = useStyles();
  const iconStyle = activeTab === index ? { fontSize: "36" } : {};

  return (
    <div className={classes.tabLabelIcon}>
      <Icon index={0} style={iconStyle} />
      {showLabel && <span className={classes.iconLabel}>{label}</span>}
    </div>
  );
};

const TabHeader = ({ activeTab, onTabChange, showLabels }) => {
  const classes = useStyles();
  return (
    <Tabs
      value={activeTab}
      onChange={onTabChange}
      textColor="secondary"
      indicatorColor="secondary"
      variant="fullWidth"
    >
      <Tab
        icon={
          <TabIcon
            icon={SlowMotionVideoIcon}
            activeTab={activeTab}
            label="Templates"
            index={0}
            showLabel={showLabels}
          />
        }
        className={classes.tab}
        aria-label="animation"
      />
      <Tab
        icon={
          <TabIcon
            icon={ImageIcon}
            activeTab={activeTab}
            label="Images"
            index={1}
            showLabel={showLabels}
          />
        }
        className={classes.tab}
        aria-label="images"
      />
      <Tab
        icon={
          <TabIcon
            icon={ViewHeadlineIcon}
            activeTab={activeTab}
            label="Text"
            index={2}
            showLabel={showLabels}
          />
        }
        className={classes.tab}
        aria-label="text"
      />
      <Tab
        icon={
          <TabIcon
            icon={LinkIcon}
            activeTab={activeTab}
            label="Socials"
            index={3}
            showLabel={showLabels}
          />
        }
        className={classes.tab}
        aria-label="links"
      />
      <Tab
        icon={
          <TabIcon
            icon={StarsIcon}
            activeTab={activeTab}
            label="Button"
            index={4}
            showLabel={showLabels}
          />
        }
        className={classes.tab}
        aria-label="cta"
      />
      <Tab
        icon={
          <TabIcon
            icon={PublishIcon}
            activeTab={activeTab}
            label="Finish"
            index={5}
            showLabel={showLabels}
          />
        }
        className={classes.tab}
        aria-label="finish"
      />
      {/* isDevelopment && (
      <Tab
        icon={
          <TabIcon
            icon={StorageIcon}
            activeTab={activeTab}
            label="Debug"
            index={6}
            showLabel={showLabels}
          />
        }
        className={classes.tab}
        aria-label="data"
      />
      ) */}
    </Tabs>
  );
};

function TabPanel(props) {
  const { children, value, index, card, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
      className={clsx({ [classes.card]: card })}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function GliideSignatureUI(props) {
  // Signature
  const { signature } = props;

  // Styling
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const isDevelopment = false; //process.env.NODE_ENV === "development";

  // Router
  const router = useRouter();
  const paid = router && router.query && router.query.paid;

  // Tab Management
  const [activeTab, setActiveTab] = React.useState(paid ? 5 : 0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Trigger Animation Signature
  useEffect(() => {
    // if rerender and active tab isn't animation or images
    if (signature.rerender && activeTab !== 0 && activeTab !== 1) {
      console.log("Triggering Animation Render");
      callTriggerAnimationRender(signature.id);
    }
  }, [activeTab, signature.id, signature.rerender]);

  // Display variables
  const exporting = activeTab === 5;
  const hideSignature = activeTab === 0;
  const signatureLgWidth = activeTab === 5 ? 12 : 6;
  const controlsGridWidth = activeTab === 0 ? 12 : 6;
  const disableNext = activeTab > 4;
  const disablePrev = activeTab < 1;

  // Display
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container={true} spacing={4}>
          {/* Tab Header */}
          <Grid item={true} xs={12}>
            <TabHeader
              activeTab={activeTab}
              onTabChange={handleTabChange}
              showLabels={!isSmallScreen}
            />
          </Grid>
          {/* Content */}
          <Grid item xs={12} container direction="row-reverse" spacing={3}>
            {/* Selected Signature Edit Display */}
            <Grid item xs={12} lg={signatureLgWidth} hidden={hideSignature}>
              <Box display="flex" justifyContent="center">
                <Box style={{ margin: "auto", display: "inline-flex" }}>
                  <Box>
                    <Box mb={4}>
                      <GliideSignature
                        signature={signature}
                        render={exporting}
                      />
                    </Box>

                    {/* <TabPanel value={activeTab} index={5} card={true}> */}
                    { exporting &&  <TabExport signature={signature} />}
                    {/* </TabPanel> */}
                    <Box mt={4}>
                      <TabPanel value={activeTab} index={5} card={true}>
                        <TabExportHelp signature={signature} />
                      </TabPanel>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* Tab Content */}
            <Grid item={true} xs={12} lg={controlsGridWidth}>
              <TabPanel value={activeTab} index={0} card={false}>
                <TabAnimation signature={signature} />
              </TabPanel>
              <TabPanel value={activeTab} index={1} card={true}>
                <TabImages signature={signature} />
              </TabPanel>
              <TabPanel value={activeTab} index={2} card={true}>
                <TabText signature={signature} />
              </TabPanel>
              <TabPanel value={activeTab} index={3} card={true}>
                <TabLinks signature={signature} />
              </TabPanel>
              <TabPanel value={activeTab} index={4} card={true}>
                <TabCTA signature={signature} />
              </TabPanel>
              {/* {isDevelopment && (
                <TabPanel value={activeTab} index={6}>
                  <TabDebug signature={signature} />
                </TabPanel>
              )} */}
            </Grid>
          </Grid>
          {/* Prev & Next Buttons */}
          <Grid item={true} xs={12}>
            <Box display="flex" justifyContent="space-between">
              {disablePrev ? (
                <div />
              ) : (
                <GradientButton
                  text="Previous"
                  width="150px"
                  primaryColor="#1CA0FC"
                  secondaryColor="#00D5C8"
                  handleClick={() => {
                    if (!disablePrev) {
                      setActiveTab(activeTab - 1);
                    }
                  }}
                />
              )}
              {disableNext ? (
                <div />
              ) : (
                <GradientButton
                  text="Next"
                  width="150px"
                  primaryColor="#00D5C8"
                  secondaryColor="#1CA0FC"
                  handleClick={() => {
                    if (!disableNext) {
                      setActiveTab(activeTab + 1);
                    }
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default GliideSignatureUI;
